<template>
  <div class="position-picker">
    <v-autocomplete
      ref="positionPicker"
      v-model="selection"
      :items="shownPositions"
      item-text="full_name"
      label="Kartplats"
      return-object
      :clearable="shownPositions.length > 1 && selection && selection.id !== 0"
      :allow-overflow="false"
      :outlined="outlined"
      :loading="isLoadingPositionAvailability"
      :error="validation.hasError"
      :class="{ 'sub-errors-for-warning': validation.hasWarning }"
      :error-messages="validation.hasError || validation.hasWarning ? [validation.errorMessage] : []"
    >
      <template v-slot:selection="data">
        <v-list-item-avatar :color="isAvailable(data.item.id) ? data.item.avatar.color : 'grey'" class="my-0" size="32">
          <v-icon
            color="white"
            small
          >
            {{ data.item.avatar.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="py-0">
          <v-list-item-title :class="{ 'disabled--text': !isAvailable(data.item.id) }">
            <span v-text="data.item.full_name"></span>
            <small v-if="data.item.id === 0 && !isLoadingCategoryAvailability && !hasErrorCategoryAvailability" class="ml-4 py-1" v-text="categoryAvailabilityText(data.item.category.id)"></small>
            <v-progress-circular
              v-if="data.item.id === 0 && isLoadingCategoryAvailability"
              indeterminate
              size="18"
              class="ml-4"
            ></v-progress-circular>
            <small v-if="data.item.id === 0 && hasErrorCategoryAvailability && !isLoadingCategoryAvailability" class="error--text ml-4">Kunde inte hämta tillgänglighet</small>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar :color="isAvailable(data.item.id) ? data.item.avatar.color : 'grey'">
          <v-icon
            color="white"
          >
            {{ data.item.avatar.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title :class="{ 'disabled--text': !isAvailable(data.item.id) }">
            <span v-text="data.item.full_name"></span>
            <small v-if="data.item.id === 0 && !isLoadingCategoryAvailability && !hasErrorCategoryAvailability" class="ml-4" v-text="categoryAvailabilityText(data.item.category.id)"></small>
            <v-progress-circular
              v-if="data.item.id === 0 && isLoadingCategoryAvailability"
              indeterminate
              size="18"
              class="ml-4"
            ></v-progress-circular>
            <small v-if="data.item.id === 0 && hasErrorCategoryAvailability && !isLoadingCategoryAvailability" class="error--text ml-4">Kunde inte hämta tillgänglighet</small>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-if="showMapIcon" v-slot:append>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img
              class="mb-1 ml-1 mt--3 mr--2 pa-0 cursor-pointer rounded map-icon"
              max-height="48px"
              max-width="48px"
              height="48px"
              width="48px"
              :src="mapPath"
              @click.stop="mapIconClicked"
            >
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                >
                  <v-icon>
                    {{ mapIsOpen ? 'mdi-close' : 'mdi-open-in-new' }}
                  </v-icon>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PositionPicker',
  props: {
    outlined: Boolean,
    showMapIcon: Boolean,
    mapIsOpen: Boolean,
    selectedPosition: Object,
    selectedCategory: Object,
    checkIn: Object,
    checkOut: Object,
    occupancy: Number,
    dogs: Number,
    existingBookingId: {
      type: Number,
      default: 0
    },
    mandatory: Boolean,
    isOpen: Boolean
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        fetchedPositionAvailabilities: state => state.position.fetchedAvailabilities,
        fetchingPositionAvailabilities: state => state.position.fetchingAvailabilities,
        positionAvailabilitiesErrors: state => state.position.availabilitiesErrors,
        fetchedCategoryAvailabilities: state => state.position.fetchedCategoryAvailabilities,
        fetchingCategoryAvailabilities: state => state.position.fetchingCategoryAvailabilities,
        categoryAvailabilitiesErrors: state => state.position.categoryAvailabilitiesErrors,
        mapPath: state => state.position.mapPath
      }
    ),
    ...mapGetters(
      {
        positions: 'position/activePositions',
        categories: 'position/activeCategories'
      }
    ),
    selection: {
      get: function () {
        return this.selectedPosition ?? (this.mandatory ? null : this.shownPositions[0])
      },
      set: function (position) {
        this.$emit('set-position', position)
      }
    },
    validation: function () {
      if (this.selectedPosition && this.selectedPosition.id > 0 && this.positionAvailability) {
        const availability = this.positionAvailability.data.find(p => p.position_id === this.selectedPosition.id)
        if (!availability) {
          // Position på bokningen har antagligen tagits bort / ersatts
          return {
            isValidated: true,
            hasError: true,
            hasWarning: false,
            errorMessage: 'Något gick fel - välj en annan plats'
          }
        }
        return {
          isValidated: true,
          hasError: availability.has_error,
          hasWarning: availability.has_warning,
          errorMessage: availability.error_message
        }
      } else if (this.selectedCategory) {
        const categoryAvailability = this.categoryAvailability(this.selectedCategory.id)
        if (categoryAvailability !== null) {
          return {
            isValidated: true,
            hasError: categoryAvailability.has_error,
            hasWarning: categoryAvailability.has_warning,
            errorMessage: categoryAvailability.has_error || categoryAvailability.has_warning ? (categoryAvailability.form_error_text ?? categoryAvailability.text ?? '') : ''
          }
        }
      }
      return {
        isValidated: false,
        hasError: false,
        hasWarning: false,
        errorMessage: ''
      }
    },

    isLoadingCategoryAvailability: function () {
      if (this.checkIn && this.checkOut) {
        return !!this.fetchingCategoryAvailabilities.find(ca => ca.checkIn.isSame(this.checkIn) && ca.checkOut.isSame(this.checkOut) && ca.bookingId === this.existingBookingId && ca.occupancy === this.occupancy && ca.dogs === this.dogs)
      }
      return false
    },
    hasErrorCategoryAvailability: function () {
      if (this.checkIn && this.checkOut) {
        return !!this.categoryAvailabilitiesErrors.find(ca => ca.checkIn.isSame(this.checkIn) && ca.checkOut.isSame(this.checkOut) && ca.bookingId === this.existingBookingId && ca.occupancy === this.occupancy && ca.dogs === this.dogs)
      }
      return true
    },
    shownPositions: function () {
      if (this.isLoadingPositionAvailability || this.hasErrorPositionAvailability || !this.positionAvailability) {
        return []
      }
      let positions = this.selectedCategory === null ? this.positions : this.positions.filter(position => position.category.id === this.selectedCategory.id)
      if (positions.length === 0) {
        return []
      } else if (positions.length === 1) {
        return positions
      }
      positions = positions.sort((positionA, positionB) => {
        return positionA.display_order > positionB.display_order ? 1 : -1
      })

      const availablePositions = positions.filter(p => this.isAvailable(p.id))
      const unavailablePositions = positions.filter(p => !this.isAvailable(p.id))

      if (this.mandatory) {
        return availablePositions.concat(unavailablePositions)
      }

      return this.unspecifiedPositions.concat(availablePositions).concat(unavailablePositions)
    },
    unspecifiedPositions: function () {
      if (this.selectedCategory) {
        return [{
          full_name: this.selectedCategory.name,
          id: 0,
          category: this.selectedCategory,
          avatar: {
            icon: 'mdi-state-machine',
            text: '',
            color: 'blue accent-1'
          }
        }]
      }
      return this.categories.map(cat => {
        return {
          full_name: cat.name,
          id: 0,
          category: cat,
          avatar: {
            icon: 'mdi-state-machine',
            text: '',
            color: 'blue accent-1'
          }
        }
      })
    },
    positionAvailability: function () {
      return this.fetchedPositionAvailabilities.find(pa => pa.checkIn.isSame(this.checkIn) && pa.checkOut.isSame(this.checkOut) && pa.bookingId === this.existingBookingId && pa.occupancy === this.occupancy && pa.dogs === this.dogs)
    },
    isLoadingPositionAvailability: function () {
      if (this.checkIn && this.checkOut) {
        return !!this.fetchingPositionAvailabilities.find(ca => ca.checkIn.isSame(this.checkIn) && ca.checkOut.isSame(this.checkOut) && ca.bookingId === this.existingBookingId && ca.occupancy === this.occupancy && ca.dogs === this.dogs)
      }
      return false
    },
    hasErrorPositionAvailability: function () {
      if (this.checkIn && this.checkOut) {
        return !!this.positionAvailabilitiesErrors.find(ca => ca.checkIn.isSame(this.checkIn) && ca.checkOut.isSame(this.checkOut) && ca.bookingId === this.existingBookingId && ca.occupancy === this.occupancy && ca.dogs === this.dogs)
      }
      return true
    }
  },
  methods: {
    mapIconClicked: function () {
      this.$emit('map-icon-clicked')
      this.$refs.positionPicker.blur()
    },
    isAvailable: function (positionId) {
      if (!this.positionAvailability) {
        return false
      }
      if (positionId === 0) {
        // ospecificerad plats, ledig om någon plats är ledig
        return this.positionAvailability.data.filter(a => a.is_available).length > 0
      }
      const availability = this.positionAvailability.data.find(a => a.position_id === positionId)
      return availability && availability.is_available
    },
    categoryAvailability: function (categoryId) {
      if (this.checkIn && this.checkOut) {
        const allAvailability = this.fetchedCategoryAvailabilities.find(ra => ra.checkIn.isSame(this.checkIn) && ra.checkOut.isSame(this.checkOut) && ra.bookingId === this.existingBookingId && ra.occupancy === this.occupancy && ra.dogs === this.dogs)
        if (allAvailability) {
          return allAvailability.data.find(c => c.category_id === categoryId)
        }
      }
      return null
    },
    categoryAvailabilityText: function (categoryId) {
      return this.categoryAvailability(categoryId)?.text ?? ''
    },
    fetchCategoryAvailability: function () {
      if (this.checkIn && this.checkOut) {
        const bookingId = this.existingBookingId ?? null
        this.$store.dispatch('position/fetchCategoryAvailability', {
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          bookingId,
          occupancy: this.occupancy,
          dogs: this.dogs
        })
      }
    }
  },
  watch: {
    checkIn: function () {
      this.fetchCategoryAvailability()
    },
    checkOut: function () {
      this.fetchCategoryAvailability()
    },
    occupancy: function () {
      this.fetchCategoryAvailability()
    },
    dogs: function () {
      this.fetchCategoryAvailability()
    },
    validation: function () {
      this.$emit('set-validation', this.validation)
    },
    selectedPosition: function () {
      if (this.selectedPosition === null) {
        this.$emit('set-position', this.shownPositions[0])
      }
    }
  },
  created () {
    this.fetchCategoryAvailability()
  }
}
</script>

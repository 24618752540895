export const namespaced = true

export const state = {
  bookingsListCheckinsShowNights: false,
  bookingsListCheckoutsShowNights: false,
  recentBookingsShowNights: false,
  recentCheckinsCheckoutsShowNights: false,
  bookingsListCheckinsShowRemaining: true,
  bookingsListCheckoutsShowRemaining: true,
  bookingFormAlwaysShowMap: false,
  bookingFormAlwaysSendConfirmationEmail: false,
  bookingCalendarIsToggleLongPositionName: false,
  statsProductGroupSalesTableSettingShowRegisterSalesOnly: false,
  ignoreAppInstallation: false, // REVIEW: inte en inställning, flytta
  mapPositionsGreenAvailability: true // Lediga platserna på kartan är lediga (false = vita)
}

export const mutations = {
  SET_BOOKINGS_LIST_CHECKINS_SHOW_NIGHTS (state, val) {
    state.bookingsListCheckinsShowNights = val
    localStorage.setItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKINS_SHOW_NIGHTS', val ? '1' : '0')
  },
  SET_BOOKINGS_LIST_CHECKOUTS_SHOW_NIGHTS (state, val) {
    state.bookingsListCheckoutsShowNights = val
    localStorage.setItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKOUTS_SHOW_NIGHTS', val ? '1' : '0')
  },
  SET_RECENT_BOOKINGS_SHOW_NIGHTS (state, val) {
    state.recentBookingsShowNights = val
    localStorage.setItem('LOCAL_SETTING_RECENT_BOOKINGS_SHOW_NIGHTS', val ? '1' : '0')
  },
  SET_RECENT_CHECKINS_CHECKOUTS_SHOW_NIGHTS (state, val) {
    state.recentCheckinsCheckoutsShowNights = val
    localStorage.setItem('LOCAL_SETTING_RECENT_CHECKINS_CHECKOUTS_SHOW_NIGHTS', val ? '1' : '0')
  },
  SET_BOOKINGS_LIST_CHECKINS_SHOW_REMAINING (state, val) {
    state.bookingsListCheckinsShowRemaining = val
    localStorage.setItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKINS_SHOW_REMAINING', val ? '1' : '0')
  },
  SET_BOOKINGS_LIST_CHECKOUTS_SHOW_REMAINING (state, val) {
    state.bookingsListCheckoutsShowRemaining = val
    localStorage.setItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKOUTS_SHOW_REMAINING', val ? '1' : '0')
  },
  SET_BOOKING_FORM_ALWAYS_SHOW_MAP (state, val) {
    state.bookingFormAlwaysShowMap = val
    localStorage.setItem('LOCAL_SETTING_BOOKING_FORM_ALWAYS_SHOW_MAP', val ? '1' : '0')
  },
  SET_BOOKING_FORM_ALWAYS_SEND_CONFIRMATION_EMAIL (state, val) {
    state.bookingFormAlwaysSendConfirmationEmail = val
    localStorage.setItem('LOCAL_SETTING_BOOKING_FORM_ALWAYS_SEND_CONFIRMATION_EMAIL', val ? '1' : '0')
  },
  SET_BOOKING_CALENDAR_IS_TOGGLE_LONG_POSITION_NAME (state, val) {
    state.bookingCalendarIsToggleLongPositionName = val
    localStorage.setItem('LOCAL_SETTING_BOOKING_CALENDAR_IS_TOGGLE_LONG_POSITION_NAME', val ? '1' : '0')
  },
  SET_STATS_PRODUCT_GROUP_SALES_TABLE_SETTING_SHOW_REGISTER_SALES_ONLY (state, val) {
    state.statsProductGroupSalesTableSettingShowRegisterSalesOnly = val
    localStorage.setItem('LOCAL_SETTING_STATS_PRODUCT_GROUP_SALES_TABLE_SETTING_SHOW_REGISTER_SALES_ONLY', val ? '1' : '0')
  },
  SET_IGNORE_APP_INSTALLATION (state, val) {
    state.ignoreAppInstallation = val
    localStorage.setItem('LOCAL_SETTING_IGNORE_APP_INSTALLATION', val ? '1' : '0')
  },
  SET_MAP_POSITIONS_GREEN_AVAILABILITY (state, val) {
    state.mapPositionsGreenAvailability = val
    localStorage.setItem('LOCAL_SETTING_MAP_POSITIONS_GREEN_AVAILABILITY', val ? '1' : '0')
  }
}

export const actions = {
  initialise ({ commit }) {
    // OBS: får tänka på att localstorage inte synkas mellan tabbar, så inga viktiga inställningar här
    let val = localStorage.getItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKINS_SHOW_NIGHTS')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_BOOKINGS_LIST_CHECKINS_SHOW_NIGHTS', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKOUTS_SHOW_NIGHTS')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_BOOKINGS_LIST_CHECKOUTS_SHOW_NIGHTS', val)

    val = localStorage.getItem('LOCAL_SETTING_RECENT_BOOKINGS_SHOW_NIGHTS')
    val == null ? val = true : val = (val === '1') // true default
    commit('SET_RECENT_BOOKINGS_SHOW_NIGHTS', val)

    val = localStorage.getItem('LOCAL_SETTING_RECENT_CHECKINS_CHECKOUTS_SHOW_NIGHTS')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_RECENT_CHECKINS_CHECKOUTS_SHOW_NIGHTS', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKINS_SHOW_REMAINING')
    val == null ? val = true : val = (val === '1') // true default
    commit('SET_BOOKINGS_LIST_CHECKINS_SHOW_REMAINING', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKINGS_LIST_CHECKOUTS_SHOW_REMAINING')
    val == null ? val = true : val = (val === '1') // true default
    commit('SET_BOOKINGS_LIST_CHECKOUTS_SHOW_REMAINING', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKING_FORM_ALWAYS_SHOW_MAP')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_BOOKING_FORM_ALWAYS_SHOW_MAP', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKING_FORM_ALWAYS_SEND_CONFIRMATION_EMAIL')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_BOOKING_FORM_ALWAYS_SEND_CONFIRMATION_EMAIL', val)

    val = localStorage.getItem('LOCAL_SETTING_BOOKING_CALENDAR_IS_TOGGLE_LONG_POSITION_NAME')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_BOOKING_CALENDAR_IS_TOGGLE_LONG_POSITION_NAME', val)

    val = localStorage.getItem('LOCAL_SETTING_STATS_PRODUCT_GROUP_SALES_TABLE_SETTING_SHOW_REGISTER_SALES_ONLY')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_STATS_PRODUCT_GROUP_SALES_TABLE_SETTING_SHOW_REGISTER_SALES_ONLY', val)

    val = localStorage.getItem('LOCAL_SETTING_IGNORE_APP_INSTALLATION')
    val == null ? val = false : val = (val === '1') // false default
    commit('SET_IGNORE_APP_INSTALLATION', val)

    val = localStorage.getItem('MAP_POSITIONS_GREEN_AVAILABILITY')
    val == null ? val = true : val = (val === '1') // true default
    commit('SET_MAP_POSITIONS_GREEN_AVAILABILITY', val)
  }
}

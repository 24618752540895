import API from './API.js'

export default {
  //
  // GET
  //
  getBookings () {
    return API.httpClient.get('bookings/synced')
  },
  getBooking ({ bookingId }) {
    return API.httpClient.get(`bookings/${bookingId}`)
  },
  getBookingCart (id) {
    return API.httpClient.get(`bookings/${id}/cart`)
  },
  getBookingCharges (id) {
    return API.httpClient.get(`bookings/${id}/charges`)
  },
  getBookingInvoices (id) {
    return API.httpClient.get(`bookings/${id}/invoices`)
  },
  getBookingReceipts (id) {
    return API.httpClient.get(`bookings/${id}/receipts`)
  },
  getBookingEmails (id) {
    return API.httpClient.get(`bookings/${id}/emails`)
  },
  getEditingRecords (bookingId) {
    return API.httpClient.get('bookings/editing-records', {
      params: {
        booking_id: bookingId
      }
    })
  },
  getAccommodationSums (id) {
    return API.httpClient.get(`bookings/${id}/accommodation-sums`)
  },
  getAccommodationChargesCalendarItems (bookingId) {
    return API.httpClient.get('bookings/accommodation-charges-calendar', {
      params: {
        booking_id: bookingId
      }
    })
  },
  getBookingsIndex ({ requestId, offset, visibleBookings, sortedColumn, sortedDesc, filterCustom, filterCheckIn, filterCheckOut, searchString }) {
    return API.httpClient.get('bookings', {
      params: {
        request_id: requestId,
        offset,
        visible_bookings: visibleBookings,
        sorted_column: sortedColumn,
        sorted_desc: sortedDesc,
        filter_custom: filterCustom,
        filter_check_in: filterCheckIn,
        filter_check_out: filterCheckOut,
        search_string: searchString
      }
    })
  },
  getBookingSummary (bookingId) {
    return API.httpClient.get('calendar/booking-summary', {
      params: {
        booking_id: bookingId
      }
    })
  },

  //
  // POST
  //
  deleteDraft (bookingId) {
    return API.httpClient.post('bookings/delete-draft', {
      booking_id: bookingId
    })
  },
  parkBooking ({ bookingId, charges }) {
    const items = charges.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('bookings/park', {
      booking_id: bookingId,
      items
    })
  },
  saveBooking ({ booking, total, isApplyBookingFee, isApplyPositionReservationFee, isApplyElectricityFee }) {
    const customer = Object.assign({}, booking.customer)
    customer.bookings = null
    return API.httpClient.post('bookings/store', {
      customer_id: booking.customer.id,
      customer,
      position_id: booking.position?.id,
      category_id: booking.category.id,
      check_in: booking.checkIn.format('YYYYMMDD'),
      check_out: booking.checkOut.format('YYYYMMDD'),
      adults: booking.adults,
      children: booking.children,
      dogs: booking.dogs,
      expected_arrival_time: booking.expected_arrival_time,
      lodging_type_id: booking.lodgingType?.id,
      cancellation_policy_id: booking.cancellationPolicy?.id,
      type: booking.type,
      season_id: booking.season?.id,
      accommodation_price: booking.accommodation_price,
      total,
      is_apply_booking_fee: isApplyBookingFee,
      is_apply_position_reservation_fee: isApplyPositionReservationFee,
      is_apply_electricity_fee: isApplyElectricityFee
    })
  },
  updateBooking ({ booking, rebookingOption }) {
    const customer = Object.assign({}, booking.customer)
    customer.bookings = null
    return API.httpClient.post('bookings/update', {
      booking_id: booking.id,
      customer_id: booking.customer.id,
      customer,
      position_id: booking.position?.id,
      category_id: booking.category.id,
      check_in: booking.checkIn.format('YYYYMMDD'),
      check_out: booking.checkOut.format('YYYYMMDD'),
      adults: booking.adults,
      children: booking.children,
      dogs: booking.dogs,
      expected_arrival_time: booking.expected_arrival_time,
      lodging_type_id: booking.lodgingType?.id,
      cancellation_policy_id: booking.cancellationPolicy?.id,
      type: booking.type,
      season_id: booking.season?.id,
      accommodation_price: booking.accommodation_price,
      rebooking_option: rebookingOption
    })
  },
  updateRemarks ({ bookingId, remarks }) {
    return API.httpClient.post('bookings/updateremarks', {
      booking_id: bookingId,
      remarks,
      timestamp: Date.now()
    })
  },
  sendBookingConfirmation ({ bookingId }) {
    return API.httpClient.post('bookings/send-confirmation', {
      booking_id: bookingId
    })
  },
  checkIn (bookingId) {
    return API.httpClient.post('bookings/check-in', {
      booking_id: bookingId
    })
  },
  reverseCheckin (bookingId) {
    return API.httpClient.post('bookings/reverse-checkin', {
      booking_id: bookingId
    })
  },
  checkOut (bookingId) {
    return API.httpClient.post('bookings/check-out', {
      booking_id: bookingId
    })
  },
  updateCheckoutDate ({ bookingId, checkoutDate }) {
    return API.httpClient.post('bookings/update-checkout', {
      booking_id: bookingId,
      checkout_date: checkoutDate.format('YYYY-MM-DD')
    })
  },
  reverseCheckout (bookingId) {
    return API.httpClient.post('bookings/reverse-checkout', {
      booking_id: bookingId
    })
  },
  cancel (bookingId) {
    return API.httpClient.post('bookings/cancel', {
      booking_id: bookingId
    })
  },
  reverseCancel (bookingId) {
    return API.httpClient.post('booking-cancellations/reverse-cancellation', {
      booking_id: bookingId
    })
  },
  saveAccommodationCharges ({ bookingId, dates, isPeriodAccommodationCharge, price, discountType, discountValue }) {
    // För nya accommodation charges, skapade av användaren
    return API.httpClient.post('bookings/update-accommodation-charges', {
      booking_id: bookingId,
      dates,
      is_period_accommodation_charge: isPeriodAccommodationCharge,
      price,
      discount_type: discountType,
      discount_value: discountValue
    })
  },
  confirmAccommodationCharges ({ bookingId, accommodationCharges }) {
    // För att spara charges som föreslagits av server
    const items = accommodationCharges.map((accommodationCharge) => {
      const item = Object.assign({}, accommodationCharge)
      // tar bort position och product för att undvika kedjereaktion i json stringifiering
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('bookings/confirm-accommodation-charges', {
      booking_id: bookingId,
      items,
      is_dismiss: false
    })
  },
  dismissAccommodationCharges ({ bookingId, accommodationCharges }) {
    // För att spara charges som föreslagits av server
    const items = accommodationCharges.map((accommodationCharge) => {
      const item = Object.assign({}, accommodationCharge)
      // tar bort position och product för att undvika kedjereaktion i json stringifiering
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('bookings/confirm-accommodation-charges', {
      booking_id: bookingId,
      items,
      is_dismiss: true
    })
  },
  setAccommodationPrice ({ bookingId, price }) {
    return API.httpClient.post('bookings/set-accommodation-price', {
      booking_id: bookingId,
      price
    })
  }
}

<template>
  <div class="">
    <v-row>
      <v-col class="">
        <v-select
          v-model="selection"
          :items="lodgingTypes"
          item-text="name"
          label="Boendeform"
          return-object
          :outlined="outlined"
          hide-details="auto"
        >
          <template v-slot:item="data">
            <v-list-item-avatar color="primary">
              <v-icon
                color="white"
              >
                {{ data.item.lodging_type_icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'LodgingTypePicker',
  props: {
    outlined: Boolean,
    lodgingTypes: Array,
    selectedLodgingType: Object
  },
  data: () => ({}),
  computed: {
    selection: {
      get: function () {
        if (!this.selectedLodgingType) {
          this.$emit('set-lodging-type', this.lodgingTypes[0])
          return this.lodgingTypes[0]
        }
        return this.selectedLodgingType
      },
      set: function (lodgingType) {
        this.$emit('set-lodging-type', lodgingType)
      }
    }
  },
  methods: {}
}
</script>
